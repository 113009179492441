import { api_get_team_users , api_get_user_by_id, api_delet_user, api_update_team_user } from "../../../queries/users"
import { api_get_system_settings, api_put_system_settings } from "../../../queries/systemSettings"
import { toJS } from "mobx"
import { toastToSuccess, toastToError } from "../../../services/notification-service"
import { ToasterMsgs } from "../../../constants/toasterMsgs"


export const systemSettingsStoreActions = self => ({   
  async getSystemSettings() {
    try {
      self.set('loading', true)
      const res = await api_get_system_settings(self.token)

      if (res.error) throw res

      self.setSystemSettings(res.settings)

      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
      console.log(err)
    }
  },

  setSystemSettings(systemSettings) {
    self.set('CLOSING_SOON_TRESHOLD', systemSettings.CLOSING_SOON_TRESHOLD)
    self.set('DEFAULT_CLOSE_TIME', systemSettings.DEFAULT_CLOSE_TIME)
    self.set('DEFAULT_DELIVERY_COMMISSION', systemSettings.DEFAULT_DELIVERY_COMMISSION)
    self.set('DEFAULT_OPEN_TIME', systemSettings.DEFAULT_OPEN_TIME)
    self.set('DEFAULT_PICKUP_COMMISSION', systemSettings.DEFAULT_PICKUP_COMMISSION)
    self.set('DEFAULT_PREPARATION_TIME', systemSettings.DEFAULT_PREPARATION_TIME)
    self.set('DELIVERY_RATE_MULTIPLIER', systemSettings.DELIVERY_RATE_MULTIPLIER)
    self.set('LAST_SUPPORTED_VERSION', systemSettings.LAST_SUPPORTED_VERSION)
    self.set('TOTAL_SERVICE_TIME', systemSettings.TOTAL_SERVICE_TIME)
    self.set('NO_ORDER', systemSettings.NO_ORDER)
  },

  async updateSystemSettings(propertyKey) {
    try {
      self.set(`${propertyKey}_loading`, true)
      
      const data = {
        propertyKey: propertyKey,
        propertyValue: self[propertyKey]
      }

      const res = await api_put_system_settings(data, self.token)

      if (res.error) throw res

      self.set(propertyKey, res.data.newValue)
      self.set(`${propertyKey}_loading`, false)

      toastToSuccess({title: `${propertyKey} was updated.`})
      return res

    } catch (err) {
      self.set(`${propertyKey}_loading`, false)
      toastToError(ToasterMsgs.genericError)
      console.log(err)
    }
},
  
  set(key, value) {
    self[key] = value
  }
  })