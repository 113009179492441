import { api_get_merchant_logs } from "../../../queries/merchants"
import { api_get_products_by_merchant_id_paginate } from "../../../queries/products"

export const merchantProductsStoreActions = self => ({
    async getMerchantProductsPaginate(page, row) {
        try {

            self.set('loading', true)
            const res = await api_get_products_by_merchant_id_paginate(
                self.token, 
                page, 
                row, 
                self.merchantId, 
                self.searchString, 
                self.selectedCategoryId, 
                self.orderBy, 
                self.statuses,
                self.inStockOnly
            )

            if (res.error) throw res

            if (res.count !== 0) {
            self.set('allProducts', res.count)
            }
            const ids = await self.productsFactory.addUpdateProducts(res.rows)
            self.set('products', ids)
            self.set('loading', false)
            return res.data

        } catch (err) {
            self.set('loading', false)
            console.log(err)
        }
    },
    querrySearch(value) {
    self.set('currentPage', 1)
    self.set('searchInput', value)
    self.getMerchantProductsPaginate(self.currentPage, self.currentRow)
    },
    async checkIfCategoryExist(id, limit) {
        try {
            self.set('loading', true)
            const category = await self.categoriesFactory.getCategory(id)
            if (category.error) throw category
            self.set('loading', false)
            self.set('selectedCategory', category.id)
            self.getMerchantProductsPaginate(1, limit)
            return category
        } catch (err) {
            self.set('loading', false)
            console.log(err)
        }
      },
    async getMerchantLogs() {
        try {
            const res = await api_get_merchant_logs(self.token, self.merchantId)

            if (res.error) throw res

            self.set('merchantLogs', res.data)

            return res.data
        } catch (err) {
            return err
        }
    },
    setOrderBy(entity) {
        let e = entity.toUpperCase()

        let orderTable = ["DESC", "ASC", null]

        let currentOrder = self.orderBy ? self.orderBy.split(",")[1] : null

        // Find the index of the current order
        const currentIndex = orderTable.indexOf(currentOrder);

        // Calculate the next index in a circular manner
        const nextIndex = (currentIndex + 1) % orderTable.length;

        
        let order = orderTable[nextIndex]

        if (!order) {
            self.set("orderBy", null)
            return
        }

        self.set("orderBy", `${entity},${order}`)
    },
    set(key, value) {
        self[key] = value
      }
})