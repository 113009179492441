import axios from "axios"
import { _throw } from "../utils/error-service"
import { API, BASIC_AUTH } from '../constants/system'

export const api_get_merchant = async (token, id) => {
  try {
    const res = await axios.get(`${API}/merchant/${id}`, {
      headers: {
        'Authorization': token ? token : BASIC_AUTH
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_merchants = async (token) => {
  try {
    const res = await axios.get(`${API}/cms/merchants`, {
      headers: {
        'Authorization': token 
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_merchant_storefront = async (token, id) => {
  try {
    const res = await axios.get(`${API}/cms/storefront/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_merchant_storefront = async (data, token, id) => {
  try {
    const res = await axios.put(`${API}/cms/storefront/${id}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_merchant_location = async (data, token, id) => {
  try {
    const res = await axios.put(`${API}/cms/merchant/${id}/update-location`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}


export const api_pause_orders = async (token, id) => {
  try {
    const res = await axios.put(`${API}/cms/pause-orders/${id}`, {}, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_resume_orders = async (token, id) => {
  try {
    const res = await axios.put(`${API}/cms/resume-orders/${id}`, {}, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}


export const api_get_merchant_logs = async (token, id) => {
  try {
    const res = await axios.get(`${API}/cms/merchant/${id}/logs`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}