
export const productActions = self => ({
  update(product) {
    self.set('sku', product.sku)
    self.set('title', product.title)
    self.set('description', product.description)
    self.set('basePrice', product.basePrice)
    self.set('tax', product.tax)
    self.set('stock', product.stock)
    self.set('thc', product.thc)
    self.set('cbd', product.cbd)
    self.set('effects', product.effects)
    self.set('type', product.type)
    self.set('city', product.city)
    self.set('post', product.post)
    self.set('state', product.state)
    self.set('country', product.country)
    self.set('hero', product.hero)
    self.set('assets', product.assets)
    self.set('category', product.categoryId)
    self.set('status', product.status)
    if (product.quantity !== undefined) {
    self.set('quantity', product.quantity)
    }
  },

  set(key, value) {
    self[key] = value
  }
})